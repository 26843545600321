import React from 'react'

const Feedback = () => {
  return (
    <>
    <div className='p-3 text-white bg-black text-3xl flex sticky'>Feedback</div>
    <div className='pt-3 p-2 pb-1 text-3xl text-white mt-24'>
      Hello
    </div>
    
    </>
  )
}

export default Feedback
