import { Carousel, Ticker, AboutUs, Location, Footer } from "../components";

const Home = () => {
  return (
    <div>
      <Carousel />
      <Ticker />
      <AboutUs />
      <Location />
      <Footer />
    </div>
  );
};

export default Home;
